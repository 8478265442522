import React from 'react';
import styled from 'styled-components';
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { breakpoints } from '../styles/Breakpoints';
import { Helmet } from "react-helmet";

const PageHeader = styled.div`
    margin: 100px 0 40px;
    @media (min-width: ${breakpoints.small}) {
        margin: 140px 0 68px;
    }
    h1 {
        font-size: 2rem;
        color: var(--green);
        text-align: center;
        padding: 0 20px;
        line-height: 1.3;
    }
`

const TestimonialsContainer = styled.div`
    max-width: 900px;
    padding: 0 20px;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    @media (min-width: ${breakpoints.med}) {
        flex-direction: row;
    }
    > div {
        background-color: var(--green-light);
        &:nth-child(even) {
            background-color: var(--sand-light);
        }
    }
`

const Testimonial = styled.div`
    display: grid;
    grid-template-columns: 90px 1fr;
    padding: 20px;
    border-radius: 12px;
    font-size: .9375rem;
    font-weight: 600;
    margin-bottom: 40px;
    .gatsby-image-wrapper {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background-color: #666;
        display: block;
        z-index: 0;
    }
    .name {
        font-weight: normal;
    }
`

const Testimonials = () => {
    const testimonialPics = useStaticQuery(graphql`
		{
			allFile(filter: {relativeDirectory: {eq: "testimonials"}}, sort: {fields: name}) {
                edges {
                    node {
                        childImageSharp {full: gatsbyImageData(layout: FULL_WIDTH)}
                        name
                    }
                }
            }
		}
	`);
    const picsArray = testimonialPics.allFile.edges.map(({node}) => node.childImageSharp);
    return (
        <Layout alwaysSticky={true} currentPage={"testimonials"}>
            <Helmet>
                <title>Testimonials :: Tide &amp; Tone Photography</title>
            </Helmet>
            <PageHeader>
                <h1 className="subpage-title">Testimonials</h1>
            </PageHeader>
            <TestimonialsContainer>
                <Testimonial>
                    <GatsbyImage image={getImage(picsArray[5].full)} className="pic" alt="The Dickards Testimonial Photo" />
                    <div>
                        <p>"I never thought we’d have so much fun getting our pictures taken! My husband and I have a huge height difference (I’m 5’3” and he’s 6’9”), needless to say, our pictures are normally a bit awkward. Chad came with incredible poses and ideas for us and every single picture came out so great! My favorite ones were with our 3 year old pup! No clue how he got great shots with her hyper self but it worked! Thank you so much, Chad!"</p>
                        <p className="name">&#8212; The Dickards</p>
                    </div>
                </Testimonial>
                <Testimonial>
                    <GatsbyImage image={getImage(picsArray[4].full)} className="pic" alt="The Wards Testimonial Photo" />
                    <div>
                        <p>"We could not be more thrilled with our family pictures taken by Chad at Tide &amp; Tone. He did an amazing job and we can not wait till our next event to use his services again."</p>
                        <p className="name">&#8212; The Wards</p>
                    </div>
                </Testimonial>
                <Testimonial>
                    <GatsbyImage image={getImage(picsArray[3].full)} className="pic" alt="The Roths Testimonial Photo" />
                    <div>
                        <p>"Excellent experience! Chad took photos of my family, and wow - they are incredible. The shots he was able to get are amazing. The photographs of my daughter are so, so special. Thank you Chad!"</p>
                        <p className="name">&#8212; The Roths</p>
                    </div>
                </Testimonial>
                <Testimonial>
                    <GatsbyImage image={getImage(picsArray[2].full)} className="pic" alt="The Kupinskis Testimonial Photo" />
                    <div>
                        <p>"We had lots of fun on our photo shoot! I loved our photos and would highly recommend him to anyone. Thanks, Chad! I look forward to enjoying these photos for years to come."</p>
                        <p className="name">&#8212; The Kupinskis</p>
                    </div>
                </Testimonial>
                <Testimonial>
                    <GatsbyImage image={getImage(picsArray[1].full)} className="pic" alt="The Pattersons Testimonial Photo" />
                    <div>
                        <p>"Oh my goodness I LOVE the photos!! I can not thank you enough!"</p>
                        <p className="name">&#8212; The Pattersons</p>
                    </div>
                </Testimonial>
                <Testimonial>
                    <GatsbyImage image={getImage(picsArray[0].full)} className="pic" alt="The Carsons Testimonial Photo" />
                    <div>
                        <p>"Thank you again, we love them! That's the most pictures we’ve ever had of her smiling. You did a phenomenal job!"</p>
                        <p className="name">&#8212; The Carsons</p>
                    </div>
                </Testimonial>
            </TestimonialsContainer>
        </Layout>
    )
}
export default Testimonials;